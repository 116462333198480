<template>
  <div class="mcia-outer-container">
    <div class="mcia-inner-container">
      <mds-layout-grid class="hero-banner">
        <mds-row
          class="hero-banner-row"
          align-vertical="center"
          align-horiontal="center"
        >
          <mds-col>
            <div class="hero-banner-row-poweredby">
              {{ poweredbyBannerData.title }}
              <img class="hero-banner-row-img" :src="imagePath" />
            </div>
            <div v-html="poweredbyBannerData.description" class="hero-banner-row-desc" />
            <div>
              <mds-button
                variation="secondary"
                size="medium"
                target="_blank"
                :href="poweredbyBannerData.dealxLink"
              >
                {{ poweredbyBannerData.knowMore }}
              </mds-button>
            </div>
          </mds-col>
        </mds-row>
      </mds-layout-grid>
    </div>
  </div>
</template>

<script>
import { MdsLayoutGrid, MdsRow, MdsCol } from '@mds/layout-grid';
import { MdsButton } from '@mds/button';
import BannerData from "@/data/banner.json";

export default {
  name: 'PoweredBy',
  components: {
    MdsLayoutGrid,
    MdsRow,
    MdsCol,
    MdsButton,
  },
  computed: {
      imagePath() {
        return require('../../assets/' + this.poweredbyBannerData.imageFileName);
      }
    },
  data() {
    return {
      poweredbyBannerData: BannerData.poweredbyBanner,
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.hero-banner {
  height: 100%;
  &-row {
    height: 100%;
    text-align: left;
    &-poweredby {
      @include mds-level-1-heading();
      font-weight: 300;
      font-size: 23px;
      line-height: 36px;
      display: flex;
      align-items: center;
      letter-spacing: 0.08em;
      padding-bottom: $mds-space-1-x;
    }
    &-img{
      padding-left: $mds-space-1-x;
    }
    &-desc {
      @include mds-level-3-heading();
      font-weight: 300;
      font-size: 20px;
      line-height: 25px;
      display: flex;
      align-items: center;
      padding-bottom: $mds-space-2-x;
    }
  }
}

.mcia-outer-container {
  box-sizing: border-box;
  background-image: url('../../assets/analytics-poweredby-banner.png');
  background-repeat: no-repeat;
  background-size: cover;
  height: 250px;
  .mcia-inner-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
  }
}
</style>
