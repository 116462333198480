<template>
  <div class="analytics-page-margin">
    <PlatformItem
      v-for="item in platformData"
      :key="item.title"
      :title="item.title"
      :introduction="item.introduction"
      :keyPoints="item.keyPoints"
      :imageFileName="item.imageFileName"
      :flipped="item.flipped"
      :findOutMoreLink="item.findOutMoreLink"
    />
  </div>
</template>

<script>
import AnalyticsData from "@/data/creditAnalytics.json";
import PlatformItem from "@/components/CreditAnalytics/PlatformItem.vue";

export default {
  name: 'Platform',
  components: {
    PlatformItem,
  },
  data() {
    return {
      platformData: AnalyticsData?.platform,
    };
  },
};
</script>
<style scoped lang="scss">
.analytics-page-margin {
  margin-top: calc(#{$mds-space-8-x} + #{$mds-space-three-quarter-x});
}
</style>
