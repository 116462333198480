<template>
  <div>
    <Banner
      :title="analyticsBannerData.title"
      :description="analyticsBannerData.description"
      :imageName="analyticsDealXBannerData.backgroundImage"
      :height="analyticsDealXBannerData.bannerHeight"
      :showDealXBannerIcon="analyticsDealXBannerData.showDealXBannerIcon"
      :margin="analyticsDealXBannerData.margin"
      :rowHeight="analyticsDealXBannerData.rowHeight"
    />
    <Platform />
    <PoweredBy />
    <mds-layout-grid class="hero-banner">
      <mds-row>
        <mds-col>
          <div class="align-center">
            <div class="title">{{ structuredBannerData.title }}</div>
          </div>
        </mds-col>
      </mds-row>
    </mds-layout-grid>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { MdsLayoutGrid, MdsRow, MdsCol } from '@mds/layout-grid';
import Banner from '../components/Common/Banner.vue';
import Platform from '../components/CreditAnalytics/Platform.vue';
import PoweredBy from '../components/CreditAnalytics/PoweredBy.vue';
import BannerData from '@/data/banner.json';

export default {
  name: "Analytics",
  components: {
    MdsLayoutGrid,
    MdsRow,
    MdsCol,
    Banner,
    Platform,
    PoweredBy
  },
  created() {
    this.saveSelectedProducts(['cre_analytics']);
  },
  mounted() {
    this.$contactType = "cre_analytics";
    this.saveShowSignInButton(true);
    this.saveActiveSignInLink(process.env.VUE_APP_ANALYTICS_SIGNIN_URL);
  },
  destroyed() {
    this.$contactType = "common";
    this.saveShowSignInButton(false);
  },
  data() {
    return {
      analyticsBannerData: BannerData.analyticsBanner,
      analyticsDealXBannerData: BannerData.dealXBanner,
      structuredBannerData: BannerData.structuredBanner,
    };
  },
  methods: {
    ...mapActions({
      saveActiveSignInLink: 'signIn/setActiveSignInLink',
      saveShowSignInButton: 'signIn/setSignInVisibility',
      saveSelectedProducts: 'signIn/setSelectedProducts',
    }),
  }
  };
</script>

<style lang="scss" scoped>
.hero-banner {
    box-sizing: border-box;
    background-image: url("../../src/assets/analytics-bottom-banner.png");
    background-repeat: no-repeat;
    background-size: cover;
    height: 159px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .hero-banner .title {
    @include mds-level-3-heading($bold: true);
    color: $mds-color-white;
    font-weight: 700;
    font-size: 28px;
    line-height: 34px;
    letter-spacing: 0.03em;
    @media (max-width: $mds-breakpoint-m) {
      margin-left: 12px;
    }
  }
</style>
